// import { useContext } from 'react'; 
// // import ReactPlayer from 'react-player/youtube'; 
// import UserContext from '../../Context/user-context';
// import styles from './Curriculum.module.less';

// const Curriculum = (props) => {
//   const userCtx = useContext(UserContext);
//   const { loading, data } = userCtx;
//   let step = 1;
//   return (
//     <div className='container'>
//       {loading ? null : data ? (
//         <>
//           <div className='box'>

//     {data.program[0].name === 'GTI YLP' ? (
//     // Render only the "Welcome" message
//     <>
//       Welcome to The {data.program[0].name} Family. We hope you are excited about your journey.
//     </>
//   ) :(
//     // Render the full content for other program names
//     <>
    
//             Welcome to The {data.program[0].name} Family. We hope you are excited
//             about your journey.
//             {
//             data.program[0].name !== "GGI Fellowship(10 weeks)" && (
//             <div className={`${styles.item} box`}>
//               <img
//                 className={styles.item__img}
//                 src={data.program[0].image2}
//                 alt='Global Governance Initiative'
//               />
//             </div>
//               )
//             }
//             {data.program[0].name !== "GTI Feynman Fellow" && (
//   <>
//     {data.program[0].name !== 'GGI Executives 6 months' && (
//   <>
//     <p style={{ fontWeight: 600 }}>
//       To start with, please mandatorily watch this exclusive message
//       from our co-founder Shatakshi as your first step to understand how
//       best to leverage the {data.program[0].name} ecosystem.
//     </p>
//     <div className={styles.playerWrapper}>
//       <iframe
//         width="100%"
//         height="100%"
//         src={data.program[0].video}
//         title="Scholars and Fellows Onboarding"
//         frameBorder="0"
//         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//         allowFullScreen
//       ></iframe>
//     </div>
//   </>
// )}

//   </>
// )}
//             {data.program[0].name !== "Impact Lab Young Leaders" &&
//             data.program[0].name !== "GGI Fellowship(10 weeks)" && (
//             <div className={`${styles.item} box`}>
//               <img
//                 className={styles.item__img}
//                 src={data.program[0].image}
//                 alt='Global Governance Initiative'
//               />  
//             </div>
//              )}
//             </>
//   )}
//           </div>
//           {data.program[0].upskilling.masterclass && (
//             <div className='box'>
//               <h3 className='box-title'>
//                 Step {step++} - Masterclasses (Live and Interactive)
//               </h3>
//               <p>
//                 Learning and Skill Development via Masterclass Global
//                 Practitioners who have been CEOs/Chairman/Partners/CXOs of BCG,
//                 McKinsey and leading product and policy firms
//               </p>
//               <div className={styles.masterclass__container}>
//                 <h3 className={styles.masterclass__heading}>Mandatory</h3>
//                 {data.program[0].masterclass.mandatory.map((item, index) => (
//                   <div key={index}>
//                     <h4 className={data.program[0].name === "ALT IIT Scholar" ? styles.masterclass__greenText : styles.masterclass__title}>
//                       {item.masterclassCode} {item.title}
//                     </h4>
//                     <ul className={styles.masterclass__list}>
//                       {item.description.map((item, index) => (
//                         <li key={index}>{item}</li>
//                       ))}
//                     </ul>
//                   </div>
//                 ))}
//               </div>
//               {data.program[0].masterclass.optional[0] && (
//                 <div className={styles.masterclass__container}>
//                   <h3 className={styles.masterclass__heading}>Optional</h3>
//                   {data.program[0].masterclass.optional.map((item, index) => (
//                     <div key={index}>
//                       <h4 className={styles.masterclass__title}>
//                         {item.masterclassCode} {item.title}
//                       </h4>
//                       <ul className={styles.masterclass__list}>
//                         {item.description.map((item, index) => (
//                           <li key={index}>{item}</li>
//                         ))}
//                       </ul>
//                     </div>
//                   ))}
//                 </div>
//               )}
//             </div>
//           )}

//           {data.program[0].curriculum.research && (
//             <div className='box'>
//               <h3 className='box-title'>
//                 Step {step++} - {data.program[0].curriculum.research.title}{' '}
//                 (Live and interactive)
//               </h3>
//               <p>{data.program[0].curriculum.research.content}</p>
//             </div>
//           )}

//           {data.program[0].curriculum.mentorCase && (
//             <div className='box'>
//               <h3 className='box-title'>
//                 Step {step++} - {data.program[0].curriculum.mentorCase.title} (Live and
//                 interactive)
//               </h3>
//               <p>{data.program[0].curriculum.mentorCase.content}</p>
//             </div>
//           )}

//           {data.program[0].curriculum.liveConsulting && (
//             <div className='box'>
//               <h3 className='box-title'>
//                 Step {step++} - {data.program[0].curriculum.liveConsulting.title} (Live
//                 and interactive)
//               </h3>
//               <p>{data.program[0].curriculum.liveConsulting.content}</p>
//             </div>
//           )}

//           {data.program[0].curriculum.schrodinger && (
//             <div className='box'>
//               <h3 className='box-title'>
//                 Step {step++} - Schrodinger (Interactive with {data.program[0].name === "Impact Lab Young Leaders" ? "Impact Lab, YLP users" : "GGI users"})
//               </h3>
//               <p>
//                 Case Study Prep with {data.program[0].name === "Impact Lab Young Leaders" ? "Impact Lab, YLP" : "GGI"} Schrodinger- exclusive AI based
//                 propriety learning tool designed exclusively for {data.program[0].name === "Impact Lab Young Leaders" ? "Impact Lab, YLP" : "GGI"} community
//               </p>
//               <div>
//                 {data.program[0].curriculum.schrodinger.map((item) => (
//                   <div key={item.case} className={styles.schrodinger__case}>
//                     <h4>Case {item.case}</h4>
//                     <h4 className={data.program[0].name === "ALT IIT Scholar" ? styles.masterclass__greenText : styles.masterclass__title}>
//                       {item.title}
//                     </h4>
//                     <p>{item.desc}</p>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           )}

//           {data.program[0].curriculum.trinity && (
//             <div className='box'>
//               <h3 className='box-title'>Step {step++} - Trinity (Self Paced)</h3>
//               <p>Mini Consulting & Policy Series with Trinity</p>
//               <div>
//                 {data.program[0].curriculum.trinity.map((item) => (
//                   <div key={item.lesson} className={styles.trinity__lesson}>
//                     <h4>Lesson {item.lesson}</h4>
//                     <p>{item.desc}</p>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           )}

//           {data.program[0].curriculum.mbaIn10 && (
//             <div className='box'>
//               <h3 className='box-title'>
//                 Step {step++} - MBA in 10 mins (Self Paced)
//               </h3>
//               <p>Popular MBA in 10 minutes series with Shatakshi Sharma</p>
//               <p>{data.program[0].curriculum.mbaIn10}</p>
//             </div>
//           )}
//           {data.program[0].curriculum.ggiProjects && (
//             <div className='box'>
//               <h3 className='box-title'>Step {step++} - {data.program[0].name === "Impact Lab Young Leaders" ? "Impact Lab, YLP" : "GGI"} Projects (Teamwork)</h3>
//               <p>
//               {data.program[0].name === "Impact Lab Young Leaders" ? "Impact Lab, YLP" : "GGI"} Scholars, on a voluntary basis, make a team of 3-5 {data.program[0].name === "Impact Lab Young Leaders" ? "Impact Lab, YLP " : "GGI "}
//                  scholars members only for {data.program[0].name === "Impact Lab Young Leaders" ? "Impact Lab, YLP" : "GGI"} Projects.
//               </p>
//               <p>{data.program[0].curriculum.ggiProjects}</p>
//             </div>
//           )}
//           {data.program[0].curriculum.startupweekend && (
//             <div className='box'>
//               <h3 className='box-title'>
//   Step {step++} - 
//   {data.program[0].name === 'GGI Executives 6 months' 
//     ? "Deep Dive" 
//     : (data.program[0].name === "Global Tech Initiative Scholar (6 months)"  
//        || data.program[0].name === "GTI Feynman Fellow"  
//        || data.program[0].name === "Global Tech Initiative Scholar (4 months)") 
//       ? "Global Tech Initiative" 
//       : "GGI"
//   } Startup Weekend
// </h3>
//               <p>
//                 {data.program[0].curriculum.startupweekend}               
//               </p>
//               <p>{data.program[0].curriculum.startupweekend2}</p>
//               <p>{data.program[0].curriculum.ggiProjects}</p>
//             </div>
//           )}
//           {/* {data.program[0].curriculum.deepdive && (
//             <div className='box'>
//               <h3 className='box-title'>
//                 Step {step++} - Live Deep Dive Sessions
//               </h3>            
//               <p>{data.program[0].curriculum.deepdive}</p>
//               <p>{data.program[0].curriculum.deepdive2}</p>
//             </div>
//           )} */}
//           {data.program[0].curriculum.deepdivefellow && (
//             <div className='box'>
//               <h3 className='box-title'>
//                 Step {step++} - Live Deep Dive Sessions- Post your graduation level 3 completion
//               </h3>            
//               <p>{data.program[0].curriculum.deepdivefellow}</p>
//             </div>
//           )}

//           {data.program[0].resources.schedule && (
//             <div className={`${styles.resources} box`}>
//               <h3>Other Resources</h3>
//               {data.program[0].resources.schedule && (
//                 <a
//                   href={data.program[0].resources.schedule}
//                   target='_blank'
//                   rel='noopener noreferrer'
//                 >
//                   Download the Schedule
//                 </a>
//               )}
//               {data.program[0].resources.curriculum && (
//                 <a
//                   href={data.program[0].resources.curriculum}
//                   target='_blank'
//                   rel='noopener noreferrer'
//                 >
//                   Download the Curriculum
//                 </a>
//               )}
//             </div>
//           )}
//         </>
//       ) : null}
//       <div className={styles.gap}></div>
//       <div className='box'>
//         <p>
//           Hi there, here is our LMS feedback{' '}
//           <a
//             href='https://forms.gle/ZSuuQYrcQkLNgamA9/'
//             target='_blank'
//             rel='noopener noreferrer'
//           >
//             link
//           </a>{' '}
//           if in case you find difficulty in navigating our LMS or if you have
//           suggestion for our tech team.
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Curriculum;

import { useContext } from "react";
// import ReactPlayer from 'react-player/youtube';
import UserContext from "../../Context/user-context";
import styles from "./Curriculum.module.less";
import { Button } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Curriculum = (props) => {
  const userCtx = useContext(UserContext);
  const { loading, data } = userCtx;
  let step = 1;
  const programsWithGTICard = ["Impact Lab Young Leaders", "Policy Scholar", "Impact MBA Scholar", "Impact Fellowship", "GGI Fellowship(10 weeks)", "GGI Executives 6 months", "Impact Fellowship(1 year)", "Impact MBA Scholar Plus","Policy Scholar Plus", "GGI Tech MBA (3 Months)", "GGI Tech MBA (6 Months)"];

  const programsWithGGICard = ["GTI Feynman Fellow", "Global Tech Initiative Scholar (6 months)", "GTI YLP", "GGI Tech Policy", "Global Tech Initiative Scholar (4 months)"];

  return (
    <div className="container">
      {loading ? null : data ? (
        <>
          <h1 style={{ fontWeight: 600 }}>Upcoming programs</h1>
<div style={{ display: "flex", gap: "50px"}}>
  {programsWithGTICard.includes(data.program[0].name) && (
          <div style={{ border: "1px solid lightgray", width: "300px", padding: "10px", marginLeft: "50px"  }} >
            <div class="workshop-container">
              <div class="workshop-card">
                <div class="card-header" style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold"}}>
                  <span class="badge">Global Tech Initiative</span>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <img src='./gti.jpg' height={120} alt="" />
                </div>

                <div class="card-content">
                  <h1>GTI Feynman Fellowship</h1>
                  <div className={styles.infoContainer}>
                    <p>
                      <strong>Duration:</strong> 6 Months
                    </p>
                  </div>

                  <p> <strong>Description:</strong> Get into Artificial Intelligence & Product Management, Mentorship by Experts from Tesla, Paypal & Leading Generative AI & Join Global Network at
GTI Feynman Fellowship
To Become a Young Tech Leader</p>
                  <div className={styles.buttonContainer}>
                    <div className={styles.item__btn}>
                      <Button
                        type="primary"
                        icon={<LogoutOutlined rotate="-45" />}
                        href="https://www.globaltechinitiative.org/feynmanfellowship"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Details
                      </Button>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          )}
          {programsWithGGICard.includes(data.program[0].name) && (
          <div style={{ border: "1px solid lightgray", width: "300px", padding: "10px", marginLeft: "50px" }} >
            <div class="workshop-container">
              <div class="workshop-card">
                <div class="card-header" style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold"}}>
                  <span class="badge">Global Governance Initiative</span>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <img src='./logo.png' height={120} alt="" />
                </div>

                <div class="card-content">
                  <h1>GGI</h1>
                  <div className={styles.infoContainer}>
                    <p>
                      <strong>Duration:</strong> 6 Months, 1 Year
                    </p>
                  </div>

                  <p> <strong>Description:</strong> Get into Management & Development Consulting,
Mentorship by BCG, Mckinsey Consultants
& Join Global Network at GGI Impact Fellowship
To Become a Young Leader<br/><br/><br/></p>
                  <div className={styles.buttonContainer}>
                    <div className={styles.item__btn}>
                      <Button
                        type="primary"
                        icon={<LogoutOutlined rotate="-45" />}
                        href="https://www.globalgovernanceinitiative.org/impact-fellowship"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Details
                      </Button>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          )}
          <div style={{ border: "1px solid lightgray", width: "300px", padding: "10px"  }} >
            <div class="workshop-container">
              <div class="workshop-card">
                <div class="card-header" style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold"}}>
                  <span class="badge">Misfits</span>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <img src='./misfits_new.png' height={120} alt="" />
                </div>

                <div class="card-content">
                  <h1>MISFITS</h1>
                  <div className={styles.infoContainer}>
                    <p>
                      <strong>Duration:</strong> 5 Year subscription
                    </p>
                  </div>

                  <p> <strong>Description:</strong> NEXT 100 IFS <br/><br/> Revolutionize your UPSC Preparation with affordable, innovative methods tailored for Indian Foreign Service aspirants<br/><br/></p>
                  <div className={styles.buttonContainer}>
                    <div className={styles.item__btn}>
                      <Button
                        type="primary"
                        icon={<LogoutOutlined rotate="-45" />}
                        href="https://www.misfits.bet/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Details
                      </Button>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ border: "1px solid lightgray", width: "300px", padding: "10px"  }} >
            <div class="workshop-container">
              <div class="workshop-card">
                <div class="card-header" style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold"}} >
                  <span class="badge">GGI Deep Dive</span>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}} >
                  <img src='./learnyst.png' height={120} alt="" />
                </div>

                <div class="card-content">
                  <h1>Deep Dive: Exclusive Sessions</h1>
                  <div className={styles.infoContainer}>
                    <p>
                      <strong>Duration:</strong> 3 Month subscription
                    </p>
                  </div>

                  <p> <strong>Description:</strong> Advanced Learning of Policy and Consulting from the Lens of Current Affairs
                  <br/>
                  <br/>
                  <strong>Note:</strong> Only for Graduated GGIians (Write to GGI admissions)<br/><br/></p>
                  <div className={styles.buttonContainer}>
                    <div className={styles.item__btn}>
                      <Button
                        type="primary"
                        icon={<LogoutOutlined rotate="-45" />}
                        href="https://forms.gle/jSUUrLL8XXVupGPWA"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Register Now
                      </Button>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>



          <div className="box">
            {data.program[0].name === "GTI YLP" ? (
              // Render only the "Welcome" message
              <>
                Welcome to the {data.program[0].name} family. Hope you are
                excited about your journey.
              </>
            ) : (
              // Render the full content for other program names
              <>
                Welcome to the {data.program[0].name} family. Hope you are
                excited about your journey.
                {data.program[0].name !== "GGI Fellowship(10 weeks)" && (
                  <div className={`${styles.item} box`}>
                    <img
                      className={styles.item__img}
                      src={data.program[0].image2}
                      alt="Global Governance Initiative"
                    />
                  </div>
                )}
                {data.program[0].name !== "GTI Feynman Fellow" && (
                  <>
                    <p style={{ fontWeight: 600 }}>
                      To start with, please mandatorily watch this exclusive
                      message from our co-founder Shatakshi as your first step
                      to understand how best to leverage the{" "}
                      {data.program[0].name} ecosystem.
                    </p>
                    <div className={styles.playerWrapper}>
                      {/* <ReactPlayer
          className={styles.videoPlayer}
          url={data.program[0].video}
          width='100%'
          height='100%'
        /> */}
                      <iframe
                        width="100%"
                        height="100%"
                        src={data.program[0].video}
                        title="Scholars and Fellows Onboarding"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </>
                )}
                {data.program[0].name !== "Impact Lab Young Leaders" &&
                  data.program[0].name !== "GGI Fellowship(10 weeks)" && (
                    <div className={`${styles.item} box`}>
                      <img
                        className={styles.item__img}
                        src={data.program[0].image}
                        alt="Global Governance Initiative"
                      />
                    </div>
                  )}
              </>
            )}
          </div>
          {data.program[0].upskilling.masterclass && (
            <div className="box">
              <h3 className="box-title">
                Step {step++} - Masterclasses (Live and Interactive)
              </h3>
              <p>
                Learning and Skill Development via Masterclass Global
                Practitioners who have been CEOs/Chairman/Partners/CXOs of BCG,
                McKinsey and leading product and policy firms
              </p>
              <div className={styles.masterclass__container}>
                <h3 className={styles.masterclass__heading}>Mandatory</h3>
                {data.program[0].masterclass.mandatory.map((item, index) => (
                  <div key={index}>
                    <h4
                      className={
                        data.program[0].name === "ALT IIT Scholar"
                          ? styles.masterclass__greenText
                          : styles.masterclass__title
                      }
                    >
                      {item.masterclassCode} {item.title}
                    </h4>
                    <ul className={styles.masterclass__list}>
                      {item.description.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              {data.program[0].masterclass.optional[0] && (
                <div className={styles.masterclass__container}>
                  <h3 className={styles.masterclass__heading}>Optional</h3>
                  {data.program[0].masterclass.optional.map((item, index) => (
                    <div key={index}>
                      <h4 className={styles.masterclass__title}>
                        {item.masterclassCode} {item.title}
                      </h4>
                      <ul className={styles.masterclass__list}>
                        {item.description.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          {data.program[0].curriculum.research && (
            <div className="box">
              <h3 className="box-title">
                Step {step++} - {data.program[0].curriculum.research.title}{" "}
                (Live and interactive)
              </h3>
              <p>{data.program[0].curriculum.research.content}</p>
            </div>
          )}

          {data.program[0].curriculum.mentorCase && (
            <div className="box">
              <h3 className="box-title">
                Step {step++} - {data.program[0].curriculum.mentorCase.title}{" "}
                (Live and interactive)
              </h3>
              <p>{data.program[0].curriculum.mentorCase.content}</p>
            </div>
          )}

          {data.program[0].curriculum.liveConsulting && (
            <div className="box">
              <h3 className="box-title">
                Step {step++} -{" "}
                {data.program[0].curriculum.liveConsulting.title} (Live and
                interactive)
              </h3>
              <p>{data.program[0].curriculum.liveConsulting.content}</p>
            </div>
          )}

          {data.program[0].curriculum.schrodinger && (
            <div className="box">
              <h3 className="box-title">
                Step {step++} - Schrodinger (Interactive with{" "}
                {data.program[0].name === "Impact Lab Young Leaders"
                  ? "Impact Lab, YLP users"
                  : "GGI users"}
                )
              </h3>
              <p>
                Case Study Prep with{" "}
                {data.program[0].name === "Impact Lab Young Leaders"
                  ? "Impact Lab, YLP"
                  : "GGI"}{" "}
                Schrodinger- exclusive AI based propriety learning tool designed
                exclusively for{" "}
                {data.program[0].name === "Impact Lab Young Leaders"
                  ? "Impact Lab, YLP"
                  : "GGI"}{" "}
                community
              </p>
              <div>
                {data.program[0].curriculum.schrodinger.map((item) => (
                  <div key={item.case} className={styles.schrodinger__case}>
                    <h4>Case {item.case}</h4>
                    <h4
                      className={
                        data.program[0].name === "ALT IIT Scholar"
                          ? styles.masterclass__greenText
                          : styles.masterclass__title
                      }
                    >
                      {item.title}
                    </h4>
                    <p>{item.desc}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {data.program[0].curriculum.trinity && (
            <div className="box">
              <h3 className="box-title">
                Step {step++} - Trinity (Self Paced)
              </h3>
              <p>Mini Consulting & Policy Series with Trinity</p>
              <div>
                {data.program[0].curriculum.trinity.map((item) => (
                  <div key={item.lesson} className={styles.trinity__lesson}>
                    <h4>Lesson {item.lesson}</h4>
                    <p>{item.desc}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {data.program[0].curriculum.mbaIn10 && (
            <div className="box">
              <h3 className="box-title">
                Step {step++} - MBA in 10 mins (Self Paced)
              </h3>
              <p>Popular MBA in 10 minutes series with Shatakshi Sharma</p>
              <p>{data.program[0].curriculum.mbaIn10}</p>
            </div>
          )}
          {data.program[0].curriculum.ggiProjects && (
            <div className="box">
              <h3 className="box-title">
                Step {step++} -{" "}
                {data.program[0].name === "Impact Lab Young Leaders"
                  ? "Impact Lab, YLP"
                  : "GGI"}{" "}
                Projects (Teamwork)
              </h3>
              <p>
                {data.program[0].name === "Impact Lab Young Leaders"
                  ? "Impact Lab, YLP"
                  : "GGI"}{" "}
                Scholars, on a voluntary basis, make a team of 3-5{" "}
                {data.program[0].name === "Impact Lab Young Leaders"
                  ? "Impact Lab, YLP "
                  : "GGI "}
                scholars members only for{" "}
                {data.program[0].name === "Impact Lab Young Leaders"
                  ? "Impact Lab, YLP"
                  : "GGI"}{" "}
                Projects.
              </p>
              <p>{data.program[0].curriculum.ggiProjects}</p>
            </div>
          )}
          {data.program[0].curriculum.startupweekend && (
            <div className="box">
              <h3 className="box-title">
                Step {step++} -{" "}
                {data.program[0].name ===
                  "Global Tech Initiative Scholar (6 months)" ||
                data.program[0].name === "GTI Feynman Fellow" ||
                data.program[0].name ===
                  "Global Tech Initiative Scholar (4 months)"
                  ? "Global Tech Initiative"
                  : "GGI"}{" "}
                Startup Weekend
              </h3>
              <p>{data.program[0].curriculum.startupweekend}</p>
              <p>{data.program[0].curriculum.ggiProjects}</p>
            </div>
          )}

          {data.program[0].resources.schedule && (
            <div className={`${styles.resources} box`}>
              <h3>Other Resources</h3>
              {data.program[0].resources.schedule && (
                <a
                  href={data.program[0].resources.schedule}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download the Schedule
                </a>
              )}
              {data.program[0].resources.curriculum && (
                <a
                  href={data.program[0].resources.curriculum}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download the Curriculum
                </a>
              )}
            </div>
          )}
        </>
      ) : null}
      <div className={styles.gap}></div>
      <div className="box">
        <p>
          Hi there, here is our LMS feedback{" "}
          <a
            href="https://forms.gle/ZSuuQYrcQkLNgamA9/"
            target="_blank"
            rel="noopener noreferrer"
          >
            link
          </a>{" "}
          if in case you find difficulty in navigating our LMS or if you have
          suggestion for our tech team.
        </p>
      </div>
    </div>
  );
};

export default Curriculum;
