import { useContext } from 'react';
// import axios from '../../helpers/axios-instance';
import UserContext from '../../Context/user-context';
// import { Calendar, Form, Button, Radio, Space } from 'antd';
// import styles from './Calender.module.less';

const Calender = (props) => {
  const userCtx = useContext(UserContext);
  const { data, getMyData } = userCtx;
  let masterclassDates = [];
  if (data) {
    masterclassDates = data?.masterclassDates;
  }

  // const onFormSubmit = async (values) => {
  //   let valuesArray = [];
  //   for (let key in values) {
  //     if (values[key] === 'Opt out') continue;

  //     const masterclassObject = {
  //       code: values[key].code,
  //       title: values[key].title,
  //       date: values[key].date,
  //     };

  //     valuesArray.push(masterclassObject);
  //   }

  //   try {
  //     await axios.patch(
  //       `/v1/users/setMasterclassDates`,
  //       {
  //         masterclassDates: valuesArray,
  //       },
  //       {
  //         withCredentials: true,
  //       }
  //     );
  //     getMyData();
  //   } catch (error) {
  //     console.log(error.response);
  //   }
  // };

  // const dateCellRender = (date) => {
  //   let events = [];
  //   masterclassDates.forEach((item) => {
  //     item.date.forEach((dateItem) => {
  //       if (date._d.toLocaleDateString('en-GB') === dateItem.split(' ')[0]) {
  //         events = [item.title];
  //       }
  //     });
  //   });

  //   return (
  //     <ul className={styles.events}>
  //       {events.map((item) => (
  //         <li key={item}>
  //           {item}
  //         </li>
  //       ))}
  //     </ul>
  //   );
  // };
  const datesForm = data ? (
    <>
      <div className='box'>
        <h3 className='box-title'>Select your masterclass dates</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: data.program[0].upskilling.masterclassForm,
          }}
        ></p>
      </div>
      {data.program[0].name && (
  <div className='box'>
    <h3 className='box-title'>Attendance Tracker</h3>
    <p
      dangerouslySetInnerHTML={{
        __html: data.program[0].upskilling.attendanceTracker,
      }}
    ></p>
  </div>
)}
{(data.program[0].name === "Impact Lab Young Leaders" || data.program[0].name === "GGI Executives 6 months") && (
  <div className='box'>
  <h3 className='box-title'>Deep Dive Masterclasses</h3>
  
<p>We require you to be registering on this zoom link (not to be circulated outside) mandatorily <strong>within the next 24 hours</strong> as an expression of attending the live deep dive sessions.</p>

<p><strong>Please note</strong>, you need to complete a curriculum of (N-1) Deep Dive Sessions to get your Level 2 GGI certificate with us.</p>

<p><a href="https://us02web.zoom.us/meeting/register/tZwtdeuqqT8qG9Mmj-eKIRpOIgm4BtTyIif2" target="_blank">We require you to be registering on this zoom link</a> (with your zoom registered email ID).</p>

<p>You will be required to register for the first time. Upon successful registration, you will receive the confirmation email with the link. Please save the same to access the future Live sessions of the Deep Dive Initiative.</p>

<p><strong>Duration:</strong> 6 months</p>

<p><strong>Start Date:</strong> First and Third Thursdays of the month, 3:30 PM IST (tentative) Duration: 1.5 hours</p>

<p>Recording to be provided for a day in case of absenteeism</p>

</div>
)}
      {/* <Form
        name='masterclass-dates-form'
        layout='vertical'
        onFinish={onFormSubmit}
        autoComplete='off'
        className={styles.form}
      >
        {data.program[0].masterclass.mandatory.map((item, index) => (
          <Form.Item
            label={item.title}
            name={item.code}
            rules={[
              {
                required: true,
                message: 'Please select an option!',
              },
            ]}
            key={item.title}
          >
            <Radio.Group name={`Radio_item_${index}`}>
              <Space direction='vertical'>
                <Radio value={item}>{item.date[0]}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        ))}
        {data.program[0].masterclass.optional.map((item, index) => (
          <Form.Item
            label={item.title}
            name={item.code}
            rules={[
              {
                required: true,
                message: 'Please select an option!',
              },
            ]}
            key={item.title}
          >
            <Radio.Group name={`Radio_item_${index}`}>
              <Space direction='vertical'>
                <Radio value={item}>{item.date[0]}</Radio>
                <Radio value='Opt out'>
                  Opt Out (not part of my 5 Masterclass selection)
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        ))}
        <Form.Item>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form> */}
    </>
  ) : null;

  return (
    <div className='container'>
      {!masterclassDates.length ? datesForm : null}
      {/* <Calendar
        dateCellRender={dateCellRender}
      /> */}
    </div>
  );
};

export default Calender;
