import { useContext } from "react";
import UserContext from "../../Context/user-context";
import styles from "./Curriculum.module.less";
import { Button } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cardcontent from './cardcontent.json';

const ProgramCard = () => { 
  return (
    <div style={{ border: "1px solid lightgray", width: "300px", padding: "10px" }}>
      <div className="workshop-container">
        <div className="workshop-card">
          <div className="card-header">
            {/* <span className="badge">Starts in: 8 DAYS</span> */}
          </div>
          <div>
            <img src={cardcontent.image} height={100} alt="Program" />
          </div>
          <div className="card-content">
            <h1>{cardcontent.program_name}</h1>
            <div className={styles.infoContainer}>
              <p>
                <strong>Duration:</strong> {cardcontent.duration}
              </p>
              <p>
                <strong>Program:</strong> {cardcontent.program_name}
              </p>
            </div>
            <p>{cardcontent.description}</p>
            <div className={styles.buttonContainer}>
              <div className={styles.item__btn}>
                <Button
                  type="primary"
                  icon={<LogoutOutlined rotate="-45" />}
                  href={cardcontent.registeration_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Details
                </Button>
              </div>
              <div className={styles.item__btn}>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Curriculum = (props) => {
  const userCtx = useContext(UserContext);
  const { loading, data } = userCtx;

  let step = 1;

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data || !data.program || data.program.length === 0) {
    return <div>No programs available.</div>;
  }

  const program = data.program[0];

  return (
    <div className="container">
      <h1 style={{ fontWeight: 600 }}>Upcoming Programs</h1>
      <div style={{ display: "flex", gap: "10px" }}>
        {/* {[program, program, program].map((item, index) => (
          <ProgramCard key={index} program={item} />
        ))} */}
        {data.map((program_name, index) => (
          <ProgramCard key={index} program={program_name} />
        ))}
      </div>

      <div className="box">
        <p>
          Welcome to the {program.name} family. Hope you are excited about your
          journey.
        </p>
        {program.name !== "GGI Fellowship(10 weeks)" && (
          <div className={`${styles.item} box`}>
            <img
              className={styles.item__img}
              src={program.image2}
              alt="Global Governance Initiative"
            />
          </div>
        )}
        {program.name !== "GTI Feynman Fellow" && (
          <>
            <p style={{ fontWeight: 600 }}>
              To start with, please mandatorily watch this exclusive message
              from our co-founder Shatakshi as your first step to understand
              how best to leverage the {program.name} ecosystem.
            </p>
            <div className={styles.playerWrapper}>
              <iframe
                width="100%"
                height="100%"
                src={program.video}
                title="Scholars and Fellows Onboarding"
                style={{ border: "none" }}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </>
        )}
      </div>

      {program.upskilling?.masterclass && (
        <div className="box">
          <h3 className="box-title">
            Step {step++} - Masterclasses (Live and Interactive)
          </h3>
          <p>
            Learning and Skill Development via Masterclass Global Practitioners
            who have been CEOs/Chairman/Partners/CXOs of BCG, McKinsey and
            leading product and policy firms.
          </p>
          <div className={styles.masterclass__container}>
            <h3 className={styles.masterclass__heading}>Mandatory</h3>
            {program.masterclass.mandatory.map((item, index) => (
              <div key={index}>
                <h4
                  className={
                    program.name === "ALT IIT Scholar"
                      ? styles.masterclass__greenText
                      : styles.masterclass__title
                  }
                >
                  {item.masterclassCode} {item.title}
                </h4>
                <ul className={styles.masterclass__list}>
                  {item.description.map((desc, i) => (
                    <li key={i}>{desc}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      )}

      {program.resources?.schedule && (
        <div className={`${styles.resources} box`}>
          <h3>Other Resources</h3>
          <a
            href={program.resources.schedule}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download the Schedule
          </a>
          {program.resources.curriculum && (
            <a
              href={program.resources.curriculum}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download the Curriculum
            </a>
          )}
        </div>
      )}

      <div className={styles.gap}></div>
      <div className="box">
        <p>
          Hi there, here is our LMS feedback{" "}
          <a
            href="https://forms.gle/ZSuuQYrcQkLNgamA9/"
            target="_blank"
            rel="noopener noreferrer"
          >
            link
          </a>{" "}
          if you find difficulty navigating our LMS or have suggestions for our
          tech team.
        </p>
      </div>
    </div>
  );
};

export default Curriculum;